import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';

const Header = () => {
    const scrollToContact = () => {
        document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
        <header className="bg-gray-200 text-gray-600 p-4 flex justify-between items-center">
            <h1 className="text-2xl font-bold">Riverland Properties</h1>
            <button onClick={scrollToContact} className="flex items-center gap-2 hover:text-gray-300">
                <FaPhoneAlt />
                <span>Contact Us</span>
            </button>
        </header>
        <div className="w-full border-b border-gray-300"></div>
        </>
    );
};

export default Header;
