// Map.jsx
import React from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

export default function Map({ lat, lng, zoom = 16 }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDpMAz-tliOsIIvT3_0u-0cnv1hTbc1Cbc", // Google Maps API key
  });

  if (!isLoaded) {
    return <div className="text-center text-gray-500">Loading Map...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={{ lat, lng }}
      zoom={zoom}
    >
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  );
}