import React from 'react';

const Contact = () => {
    return (
        <footer id="contact" className="bg-gray-200 p-8">
            <h2 className="text-xl font-semibold">Contact Information</h2>
            <p>Contact: Anna Del Rio</p>
            <p>Email: delrio_alan@yahoo.com</p>
            <p>Phone: +63 (916) 950-9630</p>
        </footer>
    );
};

export default Contact;
