import React, { useState } from 'react';
import { FaMapMarkerAlt, FaStar, FaUsers, FaBed, FaRulerCombined, FaBath, FaDoorClosed, FaSwimmingPool, FaUmbrellaBeach, FaCocktail, FaFlagUsa } from 'react-icons/fa'; // Importing additional icons for amenities
import Map from './Map';
import Header from './Header';
import Contact from './Contact';
import sail from './images/sailresidences.png';
import saillobby from './images/grandlobby.png';
import sailbed from './images/sailbed.png';
import sailliving from './images/sailliving.png';
import saildining from './images/saildining.png';
import sailbuilding from './images/sailbuilding.png';
import goldbath from './images/goldbath.png';
import goldbed from './images/goldbed.png';
import goldcouch from './images/goldcouch.png';
import goldkitchen from './images/goldkitchen.png';
import goldgym from './images/goldgym.png';
import golddining from './images/golddining.png';
import goldcity from './images/goldcity.jpeg';
import sailmoa from './images/sail-moa-callout.png';
import goldmoa from './images/gold-mnl-callout.png';
import { SpeedInsights } from "@vercel/speed-insights/react";

export default function Listings() {
    const [currentIndices, setCurrentIndices] = useState({});
    const listings = [
        {
            name: "FOR SALE | PASALO (Turn Over EOY 2025) | 2BR Condo with Balcony @ Gold Residences NAIA",
            location: "SMDC GOLD Residences MNL Airport, Unit #1763 / Tower C",
            title: "2BR Condo with Balcony, Corner End Unit #1763 / Tower C at Gold Residences by SMDC",
            squareFeet: "+/- 50.40 sqm",
            guests: 10,
            bedrooms: 2,
            beds: 5,
            baths: 1,
            rating: 4.97,
            reviews: 64,
            price: "19 Million / $345,000 (Negotiable USA Seller)",
            latitude: 14.50615,
            longitude: 121.00044,
            images: [
              goldcity,
              goldmoa,
              golddining,
              goldbath,
              goldbed,
              goldcouch,
              goldkitchen
            ],
            description: "FOR SALE | PASALO | 2BR Condo with Balcony, Corner End Unit #1763 / Tower C at Gold Residences by SMDC with open space view of the MNL Airport runway. Gold Residences is SMDC’s first township designed to be the gold standard in all aspects. Its 11.6-hectare master-planned community stands across Ninoy Aquino International Airport makes it easy for jetsetters to get to where they want to go. Inside, its sophisticated aesthetic, architecture, and premium amenities make it a joy to behold and live in.",
            amenities: "Soak up in the beauty of what life has to offer as you take a dip in Gold Residences leisure pools. The flourishing landscaped lounge area allows you to do activities and have fun. Boost your mood and get energized as you utilize the top-tiered facilities at the Fitness Center.",
        },
        {
            name: "FOR SALE | PASALO (Turn Over EOY 2024) | 1BR Condo with Balcony @ Sail Residences MOA",
            location: "SMDC SAIL Residences Mall of Asia Pasay City, Unit #1433 / Tower 3",
            title: "1BR Condo with Balcony, Unit #1433 / Tower 3 @ Sail Residences by SMDC",
            squareFeet: "+/- 38.75 sqm",
            guests: 4,
            bedrooms: 1,
            beds: 4,
            baths: 1,
            rating: 4.89,
            reviews: 39,
            price: "12 Million / $220,000 (Negotiable USA Seller)",
            latitude: 14.53783,
            longitude: 120.98562,
            images: [
              sail,
              sailmoa,
              sailbed,
              saildining,
              sailliving,
              saillobby,
              sailbuilding,
            ],
            description: "FOR SALE | PASALO | 1BR Condo with Balcony, Unit #1433 / Tower 3 at Sail Residences @ Mall of Asia Complex. Welcome to Sail Residences, the gem of the Mall of Asia district. Around the area of this condominium in Pasay, a vast wealth of shops, restaurants, and entertainment sites await you, as does the view of the horizon beyond Manila Bay.",
            amenities: "Features state-of-the-art facilities including a luxury pool, a modern gym, and exclusive lounge areas. Each unit boasts high-end interior design with a focus on comfort and style, perfect for those who enjoy the finer things in life."
        },
        // Add more listings here
    ];

    const selectImage = (listingIndex, imageIndex) => {
        setCurrentIndices(prev => ({ ...prev, [listingIndex]: imageIndex }));
    };

    return (
      <>
      <Header />
      <main className="max-w-6xl mx-auto p-5">
          {listings.map((listing, index) => (
              <div key={index} className="mb-10">
                  <h1 className="text-3xl font-bold mb-4">{listing.name}</h1>
                  <div className="flex flex-col lg:flex-row gap-3">
                      <div className="lg:flex-1">
                          <img src={listing.images[currentIndices[index] || 0]} alt="Main property view" className="rounded-lg w-full h-auto" />
                      </div>
                      <div className="lg:flex-1 grid grid-cols-3 grid-rows-3 gap-2">
                          {listing.images.map((img, imgIndex) => (
                              <img 
                                  key={imgIndex}
                                  src={img}
                                  alt={`Additional view ${imgIndex}`}
                                  onClick={() => selectImage(index, imgIndex)}
                                  className={`cursor-pointer rounded-lg ${currentIndices[index] === imgIndex ? 'ring-2 ring-blue-500' : ''}`}
                                  style={{ width: '100%', height: '100%', objectFit:'cover'}}
                              />
                          ))}
                      </div>
                  </div>
                  
                  <div className="flex flex-col sm:flex-row gap-3 mt-4">
                      <div className="flex-1 p-4">
                          <p className="text-xl mb-2 font-bold">{listing.title}</p>
                          <p className="text-gray-600 mb-2">
                              <FaDoorClosed className="inline mr-2" />{listing.bedrooms} bedrooms · 
                              <FaBath className="inline mr-2" />{listing.baths} bath · 
                              <FaRulerCombined className="inline mr-2" />{listing.squareFeet} sq ft 
                          </p>
                          <p className="text-lg font-bold mb-4">PHP {listing.price} <FaFlagUsa className='color:red inline mr-2' /></p>                        
                      </div>
                      <div className="flex-1 h-auto">
                          <Map
                              lat={listing.latitude}
                              lng={listing.longitude}
                          />
                          <p className="text-md mb-2"><FaMapMarkerAlt className="inline mr-2" />{listing.location}</p>
                      </div>
                  </div>
                  <div className="p-4">
                      <h2 className="text-2xl font-semibold">Description</h2>
                      <p className="text-lg mb-4">{listing.description}</p>
                      <h2 className="text-2xl font-semibold">Amenities</h2>
                      <p className="text-lg mb-4">{listing.amenities}</p>
                  </div>
                  {index < listings.length - 1 && <hr />}
              </div>
          ))}
      </main>
      <Contact />
      </>
  );
}
